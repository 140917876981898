.table--datagrid {
    //border: 1px solid $primary-color;
    tbody {
        tr {
            border-bottom: 1px solid $primary-color;
        }
        td, th {
            padding: .2em .4em;
        }
    }
}
