.btn:not(.btn--add) {
	border: none;
	position: relative;
	height: rem(40);
	line-height: rem(40);
	padding: 0 em(45);
	font-weight: 700;
	background-color: $primary-color;
	box-shadow: none !important;

	white-space: nowrap;

	color: $text-color-inverse;

	border-radius: rem(8);

	display: inline-block;

	text-decoration: none;

	transition:  $transition;

	font-weight: 600;
}

.btn.btn--danger {
    background-color: #BD2130;
}

a.btn:not(.btn--add),
input.btn:not(.btn--add),
button.btn:not(.btn--add) {
	&:hover,
	&:focus {
		text-decoration: none;
		color: $text-color-inverse;
		background-color: darken($primary-color, 5%);
	}
}

.btn--big:not(.btn--add) {
	height: rem(60);
	line-height: rem(58);

	@extend .text--large;
}

.btn--small:not(.btn--add) {
	height: rem(30);
	line-height: rem(28);

	@extend .text--small;
}

.btn--xsmall:not(.btn--add) {
	height: rem(26);
	line-height: rem(24);

	@extend .text--small;
}

.btn--bordered:not(.btn--add) {
	border: rem(3) solid $primary-color;
	background-color: transparent;
	color: $primary-color;
	&:hover,
	&:focus {
		border-color: darken($primary-color, 5%);
	}
}

.btn--secondary:not(.btn--add) {
	color: $text-color;
	background-color: $secondary-color;

	&:hover,
	&:focus {
		text-decoration: none;
		color: $text-color !important;
		background-color: darken($secondary-color, 5%) !important;
	}

}

.btn--add {
	padding: 0;
	box-shadow: none !important;

	.icon {
		transition: $transition;
	}

	&:hover {
		.icon {
			transform:scale(1.1);
		}
	}
}
