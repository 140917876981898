.page--detail {
	.teaser {
		.square--s10 {
			position: absolute;
			top: rem(90);
			right: rem(188);
		}
	}

	.fixed {
		position: absolute;
		top: 0;
		right: 0;
		width: rem(260);
	}

	.stickem-container {
		position: relative;
	}

	.stickit {
		position: fixed;
		top: rem(75);
		right: calc(50% - #{rem((1080) / 2)});
	}

	.stickit-end {
		top: auto;
		bottom: 0;
		position: absolute;
		right: 0;
	}

	.order {
		padding: rem(10) rem(16) rem(20);
		background: #e3fae3;
	}

	.section--recommendation {
		.square--2 {
			margin-top: rem(185);
		}

		.btn--abs {
			position: absolute;

			bottom: 0;
			right: rem(-30);
		}
	}
}