.form {

}

.form--register {
  max-width: rem(486);
  margin-left: auto;
  margin-right: auto;
}

.form__section {
  background: $grey;

  border-radius: rem(10);
  padding: rem(30);
  box-shadow: $boxShadow;

  @include media-breakpoint-down(xs) {
    padding: 1rem;
  }
}


.form-group {
  margin-bottom: 1.5rem;
}

.form-control-label {
  text-transform: uppercase;
  margin-bottom: .5rem;
  // color: $text-color-dark;
  // font-family: $headings-font-family;
  font-size: 1rem;
  font-weight: 700;

  display: block;
  color: $text-color;
}

.form-control {
  // @extend .text--large;
  font-size: 1rem !important;
  color: $text-color !important;
  outline: 0;
  border: $border;
  box-shadow: none !important;

  // border-radius: $borderRadiusSmall;
  padding: 0 1rem;
  background: $body-bg !important;

  width: 100%;
  border-radius: rem(8);

  &:not(textarea) {
    height: rem(40);
  }

  &:required {
    
  }

  &.text-danger {
    box-shadow: inset 0 0 0 2px $error-color !important;
  }

  &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: $text-color;
  }
  &::-moz-placeholder { /* Firefox 19+ */
    color: $text-color;
  }
  &:-ms-input-placeholder { /* IE 10+ */
    color: $text-color;
  }
  &:-moz-placeholder { /* Firefox 18- */
    color: $text-color;
  }

  @include media-breakpoint-down(xs) {
    padding-left: 10px;
    padding-right: 10px;

    // &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    //   font-size: 14px;
    // }
    // &::-moz-placeholder { /* Firefox 19+ */
    //   font-size: 14px;
    // }
    // &:-ms-input-placeholder { /* IE 10+ */
    //   font-size: 14px;
    // }
    // &:-moz-placeholder { /* Firefox 18- */
    //   font-size: 14px;
    // }
  }
}

// search
.form-control--search {
  background-image: url(../images/icons/ico-search-input.svg) !important;
  background-repeat: no-repeat !important;
  background-size: rem(18) rem(18) !important;
  background-position: center right rem(8) !important;
}

// success
.form-control.parsley-success {
  border-color: $secondary-color;
  background-color: #FAFFF2 !important;
}

// error
.form-control.parsley-error {
  border-color: $error-color;
  background-color: #FFEBEB !important;

  &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: $error-color;
  }
  &::-moz-placeholder { /* Firefox 19+ */
    color: $error-color;
  }
  &:-ms-input-placeholder { /* IE 10+ */
    color: $error-color;
  }
  &:-moz-placeholder { /* Firefox 18- */
    color: $error-color;
  }
  
  // &:not(select) {
  //   background-image: url(../images/v2/svg/ico-alert.svg) !important;
  //   background-size: rem(20) rem(18) !important;
  //   background-position: center right rem(14) !important;
  //   background-repeat: no-repeat;
  // }

  + .parsley-errors-list {
    @extend .text--semi-small;
    list-style: none;
    margin: 0;
    margin-top: .25em;
    padding: 0;
    color: $error-color;

    padding-left: rem(30);
    position: relative;

    &:before {
      content: '';
      position: absolute;

      top: rem(3);
      left: rem(8);

      width: rem(13.06);
      height: rem(11.31);

      background: url(../images/icons/ico-alert.svg);
      background-size: 100% 100%;
    }
  }
}

// https://stackoverflow.com/questions/2781549/removing-input-background-colour-for-chrome-autocomplete
// https://css-tricks.com/snippets/css/change-autocomplete-styles-webkit-browsers/
// input:-webkit-autofill,
// input:-webkit-autofill:hover,
// input:-webkit-autofill:focus
// textarea:-webkit-autofill,
// textarea:-webkit-autofill:hover,
// textarea:-webkit-autofill:focus,
// select:-webkit-autofill,
// select:-webkit-autofill:hover,
// select:-webkit-autofill:focus {

//   -webkit-text-fill-color: $text-color;

//   &:not(.parsley-success):not(.parsley-error) {
//     // -webkit-box-shadow: 0 0 0 rem(60) $body-bg inset !important;
//     // transition: all 10000s $transition-curve;
//   }

//   &.parsley-success {
//     border-color: $secondary-color;
//     background-color: #FAFFF2 !important;
//     -webkit-box-shadow: 0 0 0 rem(60) #FAFFF2 inset !important;
//   }

//   &.parsley-error {
//     border-color: $error-color;
//     background-color: #FFEBEB !important;
//     -webkit-box-shadow: 0 0 0 rem(60) #FFEBEB inset !important;
//   }
// }

// @import './inputs/_dropdown';
@import './inputs/_select';
@import './inputs/_radio';
@import './inputs/_checkbox';
@import './inputs/_toggle';
@import './inputs/_textarea';
@import './inputs/_drag-and-drop';
@import './inputs/_image';
// @import './inputs/_date';

// only iOS
@media (max-device-width:1024px) {
  @supports (-webkit-overflow-scrolling: touch) {
    select,
    textarea,
    input {
      font-size: 16px !important;
    }
  }
}