.checkbox {
  // margin-left: 2px;
}

.form-check {
  padding-left: 0;
}

.form-check-label {

  @extend .text--semi-small;
  position: relative;
  padding-left: rem(25);

  cursor: pointer;
  user-select: none;

  // transition: $transition;

  &:before,
  &:after {
    content: '';
    position: absolute;
    display: block;
    transition: $transition;
  }

  // box
  &:before {
    width: rem(14);
    height: rem(14);
    background: transparent;
    left: 0;
    top: rem(1);

    border: rem(2) solid $text-color-dark;
    border-radius: rem(3);
  }

  // check
  &:after {
    content: '';
    opacity: 0;
    transform: translate(-50%, -50%) scale(0);

    transition: $transition;

    width: rem(8);
    height: rem(6);

    background: url(../images/icons/ico-check.svg);
    background-size: 100% 100%;

    top: rem(8);
    left: rem(7);


  }

  &:hover {
    // color: $primary-color;
    &:before {
      // border-color: $primary-color !important;
      // background-color: $primary-color;
    }
  }
}

.form-check-input {
  // display: none;
  position: absolute;
  left: -99999px;
  opacity: 0;

  &:checked ~ .form-check-label:before {
    // background: $primary-color;
    // border-color: transparent;
  }

  &:checked ~ .form-check-label:after {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }

  &.text-danger ~ .form-check-label:before {
    box-shadow: inset 0 0 0 2px $error-color !important;
  }
}

.form-check.parsley-error {

  .form-check-label {
    &:before {
      border-color: $error-color;
    }
  }

  + .parsley-errors-list {
    @extend .text--semi-small;
    list-style: none;
    margin: 0;
    margin-top: .25em;
    margin-bottom: 1rem;
    padding: 0;
    color: $error-color;

    padding-left: rem(55);

    position: relative;

    &:before {
      content: '';
      position: absolute;

      top: rem(3);
      left: rem(8 + 25);

      width: rem(13.06);
      height: rem(11.31);

      background: url(../images/icons/ico-alert.svg);
      background-size: 100% 100%;
    }
  }
}

.form-check--dog {
  display: inline-block;

  .form-check-input {
    &:checked ~ .form-check-label:before {
      background: $primary-color;
    }
  }
  
  .form-check-label {
    text-indent: -9999px;

    &:before {
      border-color: $primary-color;
      border-radius: rem(10);
      border-width: rem(3);
    }

    &:after {
      background-position: center;
      background-size: 100% 100%;

      opacity: 1;
      transform: translateX(-50%) !important;

      top: auto;
      bottom: rem(5);
      left: 50%;
    }
  }
}

.form-check--dog-maly {

  .form-check-label {
    padding-left: rem(36);
    height: rem(36);
    &:before {
      width: rem(36);
      height: rem(36);
    }

    &:after {      
      background-image: url(../images/icons/ico-dog-small.png);
      width: rem(20);
      height: rem(20);
    }
  }
  
}

.form-check--dog-stredni {

  .form-check-label {
    padding-left: rem(50);
    height: rem(50);
    &:before {
      width: rem(50);
      height: rem(50);
    }

    &:after {      
      background-image: url(../images/icons/ico-dog.png);
      width: rem(36);
      height: rem(35);
    }
  }
  
}

.form-check--dog-velky {
  
  .form-check-label {
    padding-left: rem(69);
    height: rem(60);
    &:before {
      width: rem(69);
      height: rem(60);
    }

    &:after {      
      background-image: url(../images/icons/ico-dog-big.png);
      width: rem(50);
      height: rem(49);
    }
  }

}