.timeline {
	height: rem(6);
	width: 100%;
	border-radius: rem(3);

	background: #D6D6D6;

	margin-top: rem(35);
	margin-bottom: rem(60);

	position: relative;
}

.timeline__progress {
	position: absolute;
	top: 0;
	left: 0;

	width: 0;
	height: 100%;

	border-radius: rem(3);

	background: $headlings-color;

}

.timeline__date {
	position: absolute;
	font-weight: 600;
	color: #9A9A9A;
}

.timeline__date--now {
	right: 0;
	top: rem(-5);

	transform: translate(50%, -100%);
	color: $headlings-color;
}

.timeline__date--start,
.timeline__date--end {
	bottom: rem(-5);
	transform: translateY(100%);
}

.timeline__date--start {
	left: 0;
}

.timeline__date--end {
	right: 0;
}