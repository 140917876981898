/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: rem(75);
  height: rem(26);
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #A0A0A0;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: rem(20);
  width: rem(20);
  left: rem(3);
  bottom: rem(3);
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider__text {
	@extend .text--small;
	text-transform: uppercase;
	position: absolute;
	color: $text-color-inverse;

	top: 50%;
	transform: translateY(-50%);
	font-weight: 500;
	z-index: 1;

	transition: $transition-opacity;

	pointer-events: none;
}

.slider__text--yes {
	left: rem(15);
	opacity: 0;
}

.slider__text--no {
	right: rem(20);
	opacity: 1;
}

input:checked + .slider {
  background-color: #00A811;
	
	.slider__text--yes {
		opacity: 1;
	}

	.slider__text--no {
		opacity: 0;
	}
}

input:focus + .slider {
  // box-shadow: 0 0 1px #00A811;	
}

input:checked + .slider:before {
  -webkit-transform: translateX(rem(49));
  -ms-transform: translateX(rem(49));
  transform: translateX(rem(49));
}

/* Rounded sliders */
.slider.round {
  border-radius: rem(13);
}

.slider.round:before {
  border-radius: 50%;
}

