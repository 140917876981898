// // select
select.form-control {
  text-align: left;
  appearance: none;
}

// .select2,
.select {

  position: relative;
  // color: $primary-color;

  // arrow
  &:before {
    content: '';
    
    position: absolute;
    top: 50%;
    right: rem(15) !important;
    transform: translateY(-50%);

    pointer-events: none;
		background: url(../images/icons/ico-chevron-down.svg);
		background-size: 100% 100%;

		width: rem(14);
		height: rem(8);
  }

  select {
    // height: rem(60) !important;
    // background: transparent !important;
    // padding-left: rem(20);
    // padding-right: rem(40);
    text-align: left;
    appearance: none;
    // border: 1px solid $primary-color;
    outline: 0;
    cursor: pointer;

    &:invalid {
      color: $primary-color !important;
    }

    // @include media-breakpoint-down(xs) {
    //   font-size: 14px;
    // }
  }

  /* CAUTION: IE hackery ahead */
  select::-ms-expand {
    display: none; /* remove default arrow on ie10 and ie11 */
  }

  option {
    // @extend .text--semi-large;
    // font-family: Arial, Helvetica, sans-serif;
    // font-weight: normal;
    // color: $text-color;
    // &:disabled {
    //   color: $primary-color !important;
    // }
  }
}

// .select2,
// .select2-container--default .select2-selection--multiple {
//   height: auto;
//   min-height: rem(60);
//   border-color: $primary-color !important;
//   border-radius: 0;

//   padding-bottom: rem(20);
// }

// .select2-container--default .select2-selection--multiple .select2-selection__clear {
//   position: absolute;
//   top: rem(-8);
//   right: rem(-4);
//   color: $secondary-color;
// }

// .select2-container .select2-selection--multiple .select2-selection__rendered {
//   // display: flex;
//   // flex-wrap: wrap;
//   padding-right: rem(20);
// }

// .select2-container--default .select2-selection--multiple .select2-selection__choice {
//   font-size: rem(14);
//   border-radius: 0;
//   // margin-top: 0;
// }

// .select2-search.select2-search--inline {
//   position: relative;
//   top: rem(8);
//   left: rem(13);
// }

// .select2-selection__clear + .select2-selection__rendered + .select2-search.select2-search--inline {
//   position: static;
// }

// .select2-selection__clear + .select2-selection__rendered {
//   position: relative;
//   top: rem(8);
//   margin-bottom: rem(8);
// }

// .select--white {
//   select, option {
//     background: #fff !important;
//   }
// }