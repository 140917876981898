.drag-area{

	text-align: center;
  justify-content: center;
  height: 150px;
  
  display: flex;
  width: 100%;
  border-radius: 8px;
  background: #ffffff;
  align-items: center;
  justify-items: center;

  flex-direction: column;

  border: $border;

	input {
		display: none;
	}
}
.drag-area.active{
  border-color: #000;
}
.drag-area span{
  color: #9B9B9B;
  font-size: 14px;
  font-weight: 400;
	text-align: center;

	pointer-events: none;
}
.drag-area small{
  margin: .5em 0 .5em 0;
	pointer-events: none;
}
.drag-area .button{

  cursor: pointer;

  line-height: 28px;
  padding: 0 1rem;
  margin: 0;
}

.drag-area i {
  display: block;
  margin-top: 1em;
  font-size: 12px;
  font-weight: 400;

  pointer-events: none;
}

.upload__label {
  min-height: 3em;
}