.notify {
	display: block;
	position: relative;


	span {
		position: absolute;
		width: rem(21);
		height: rem(21);
		border-radius: 50%;
		text-align: center;

		background: #D6D6D6;

		color: $error-color;
		font-weight: 600;

		bottom: 0;
		right: 0;

		transform: translate(15%, 0%);
	}
}