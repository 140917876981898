.page--login {

	.header,
	.footer {
		display: none !important;
	}
	
	.section--login {
		position: relative;
		// padding-top: rem(70);
		min-height: 100vh;

		@include media-breakpoint-down(lg) {
			min-height: auto;
		}
	}

	.bg {
		position: absolute;
		height: 100%;
		width: 50%;

		top: 0;
		right: 0;

		img {
			object-fit: cover;
		}

		// @include media-breakpoint-down(lg) {
		// 	width: 45%;
		// }
	}
}