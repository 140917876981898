.footer {
  padding: rem(25) 0 rem(35);
  margin-bottom: rem(125);

  height: rem(350);
  max-height: rem(350);

  position: relative;
  z-index: 2;

  &:before {
    content: '';
    position: absolute;

    bottom: 0;
    left: 0;
    width: 100%;
    height: rem(125);

    transform: translateY(100%);

    background: $text-color-inverse;
  }
}

.footer__nav {
  .nav__item {
    margin: 0;
  }
}

.footer__subnav {

}

.footer__green {

  position: absolute;
  top: 0;
  left: 0;
  width: 100%;

  padding-right: rem(125);
  
  &:before {
    content: '';
    position: absolute;
    top: rem(-25);
    left: 0;
    width: 100%;
    height: rem(350 + 125);
    background: $primary-color;

    z-index: 0;
  }
}

.footer__socials {
  position: absolute;
  top: 0;
  right: rem(20);
}