.person {
	position: relative;
	border-bottom: 1px solid #E5E5E5;
}

.person__action {
	position: absolute;
	top: 0;
	right: 0;
	z-index: 1;
}