.icon {
  position: relative;
  display: block;
  height: auto;
}

.icon--instagram {
  width: rem(30);

  @include media-breakpoint-down(md) {
    width: rem(30 * 1.5);
  }
}

.icon--facebook {
  width: rem(13);

  @include media-breakpoint-down(md) {
    width: rem(13 * 1.5);
  }
}

// .close {
//   background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTkiIGhlaWdodD0iMTkiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHZpZXdCb3g9IjAgMCAxOSAxOSI+PGRlZnM+PHBhdGggZD0iTTIxLjU3IDQwLjJMMzguNzcgMjMiIGlkPSJhIi8+PHBhdGggZD0iTTM4LjIyIDQwLjJMMjEuNTcgMjMiIGlkPSJiIi8+PC9kZWZzPjxnIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0yMSAtMjIpIj48dXNlIHhsaW5rOmhyZWY9IiNhIiBmaWxsLW9wYWNpdHk9IjAiIGZpbGw9IiNmZmYiIHN0cm9rZT0iIzAxYTZlNiIgc3Ryb2tlLW1pdGVybGltaXQ9IjUwIiBzdHJva2Utd2lkdGg9IjIiLz48Zz48dXNlIHhsaW5rOmhyZWY9IiNiIiBmaWxsLW9wYWNpdHk9IjAiIGZpbGw9IiNmZmYiIHN0cm9rZT0iIzAxYTZlNiIgc3Ryb2tlLW1pdGVybGltaXQ9IjUwIiBzdHJva2Utd2lkdGg9IjIiLz48L2c+PC9nPjwvc3ZnPg==');
//   background-size: 100% 100% !important;
// }

// .icon--trash {
//   width: rem(24);
// }

.icon--cube {
  width: rem(38);
}

.icon--stavby {
  width: rem(120 * .75);
}

.icon--kontrolni-dny {
  width: rem(90 * .75);
}

.icon--reporty {
  width: rem(82 * .75);
}

.icon--uzivatele {
  width: rem(96 * .75);
}

.icon--dokumenty {
  width: rem(91 * .75);
}

.icon--bell {
  width: rem(24);
}

.icon--logout {
  width: rem(24);
}

.icon--googlemaps {
  width: rem(49);

  .position-relative & {
    position: absolute;
    bottom: rem(12);
    right: rem(12);
  }
}

.icon--users {
  width: rem(18);
}

.icon--user {
  width: rem(52);
}

.icon--delete {
  width: rem(24);
  top: rem(1);
}

.icon--email {
  width: rem(24);
}

.icon--phone {
  width: rem(24);
}

.icon--info-big {
  width: rem(36);
}

.icon--info-medium {
  width: rem(20);
}

.icon--calendar {
  width: rem(24);
}

.icon--camera {
  width: rem(24);
}

.icon--users {
  width: rem(24);
}

.icon--doc {
  width: rem(24);
}

.icon--pdf {
  width: rem(34);
}

.icon--add {
  width: rem(50);
}

.icon--plus {
  width: rem(20);
}

.icon--chevron-down-big {
  width: rem(28);
}

.icon--chevron-down-medium {
  width: rem(17);
}

.icon--check-circle {
  width: rem(24);
}

.icon--warning {
  width: rem(24);
}

.icon--close-modal {
  width: rem(25);
}

.icon--document {
  width: rem(40);
}