.modal-backdrop {
  &.show {
    opacity: .5 !important;
  }
}

.modal {
  padding: 1rem !important;
}

.modal-dialog {
  @include media-breakpoint-up(xs) {
    max-width: rem(930);
  }
}

.modal-content {
  border-radius: rem(5);
  // box-shadow: $shadow-box;
  border: none;
  padding: 0;

  @include media-breakpoint-down(xs) {
    padding: 1rem;
    // border-radius: rem(25);
  }
}

.modal-body {
  padding: 0 rem(36) rem(60);
}

.modal-header {
  border: none;
  padding: rem(18);
}