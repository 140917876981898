.medailon {
    cursor: pointer;

    &, &:before, &:after, *, *:before, *:after {
        backface-visibility: hidden;
        // transform:rotateZ(0);
        text-rendering: optimizeLegibility;
        -webkit-font-smoothing: antialiased;
    }
}

.medailon-input {
    position: absolute;
    left: -99999px;
    opacity: 0;

    &:checked ~ .medailon {
        .medailon__imgWrap {
            border: rem(4) solid $primary-color;
        }
    }

}

.medailon__imgWrap {
	position: relative;
	border-radius: 50%;
	overflow: hidden;
    background-color: #7ea2cc;
    background-color: #EFEFEF;

	width: rem(52);
	height: rem(52);

	transition: $transition;

	// .medailon:not(label):hover & {
	// 	transform: scale(1.1);
	// }

    flex-shrink: 0;

	.medailon--small & {
		width: rem(27);
		height: rem(27);
	}
}

.medailon__img {
	object-fit: cover;
}

// .medailon__placeholder {
//     // display: inline-block;
//     // width: 100%;
//     // height: 100%;
//     // color: #fff;
//     // text-align: center;
//     // line-height: 47px;
//     // font-size: 20px;
// }

.medailon__remove {
    position: absolute;
    width: rem(16);
    height: rem(16);
    text-align: center;
    color: $text-color-inverse;
    font-weight: 700;
    background: #f33;
    top: 0;
    left: rem(16);
    border-radius: 50%;

    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        transform: scale(1.1);  
        color: $text-color-inverse;
    }
}
