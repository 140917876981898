.card {
	display: block;
	background: $body-bg;
	
	transition: $transition;

	&:hover {
		box-shadow: $boxShadow;

		.btn {
			background-color: darken($primary-color, 5%);
		}
	}
}

.card__header {
	height: rem(60);
}

.card__title {
}

.card__visual {
	position: relative;
	padding-bottom: (141 / 200 * 100%);
	height: 0;

	.icon {
		position: absolute;
		bottom: rem(8);
		right: rem(2);
	}
}

.card__img {
	object-fit: cover;
}

.card__place {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	line-height: rem(26);

	background: transparentize(#000000, .7);
	backdrop-filter: blur(4px);

	z-index: 1;

	text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
	padding: 0 .5rem;
}

.card__body {
	color: $text-color;
}