.checking-days {

	position: relative;

	&:before {
		content: '';
		position: absolute;
		height: 100%;
		width: rem(4);
		top: rem(10);
		left: rem(50);

		background: $headlings-color;

	}

}

.checking-day {


}

.checking-day__header {
	position: relative;
	padding-left: rem(100);
	
	&:before {
		content: '';
		width: rem(46);
		height: rem(46);

		background: url(../images/icons/ico-target.svg);
		background-size: 100% 100%;

		position: absolute;
		top: rem(-8);
		left: rem(51);
	}
}

.checking-day__content {
	img {
		max-width: 100%;
	}

	.icon--chevron-down-big {
		transform: rotate(180deg);
		transition: $transition;
	}

	.collapsed {
		.icon--chevron-down-big {
			transform: rotate(0deg);
		}
	}
}