.nav:not(.nav-tabs) {
	display: block;
}

.nav__logo {
	margin-top: rem(25);
	margin-bottom: rem(45);
}

.nav__list {

	.icon {
		width: rem(19);
		path {
			stroke: $text-color !important;
		}
	}

}

.nav__list-item {

	a:hover {
		.icon {
			path {
				stroke: #0056b3 !important;
			}
		}
	}

	&.active a {
		color: $green;

		.icon {
			path {
				stroke: $green !important;
			}
		}
	}
}
