.nav-tabs {
	background: #D6D6D6;
	border-radius: rem(5) rem(5) 0 0;
	border-color: #D6D6D6;
	.nav-item {
		flex: 1;

		.nav-link:not(.active) {
			border-left: 1px solid #C8C8C8;
			border-right: 1px solid #C8C8C8;
		}

		&:first-child {
			.nav-link {
				border-left: none !important;
			}
		}
		
		&:last-child {
			.nav-link {
				border-right: none !important;
			}
		}

	}
	.nav-link {
		border: none;
		height: rem(62);

		&:hover {
			color: $text-color;
		}
	}
}

.tab-content {
	border-radius: 0 0 rem(5) rem(5);
}

.nav-tabs--gallery {

	background: transparent;
	border-radius: none;
	border-bottom: 0;
	
	img {
		width: rem(75);
		border-radius: rem(5);
	}

	.nav-item {
		flex: none;

		a {
			strong {
				transition: $transition;
			}
		}

		a:not(.active) {
			border-left: none;
			border-right: none;
		}
	}

	a.active,
	a:hover {
		strong {
			color: $primary-color !important;
		}
	}
}