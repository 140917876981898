.box {

	position: relative;
	padding: rem(40) rem(25) rem(150) rem(25);

	transition: $transition;

	&:hover {
		box-shadow: $boxShadow;
	}

	.icon {

		position: absolute;
		bottom: rem(75);
		left: 50%;
		transform: translate(-50%, 50%);

		margin-left: auto;
		margin-right: auto;
	}
}